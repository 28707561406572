<template>
  <div id="helpright" v-loading="showContentLoading">
    <div class="title">{{ val.name }}</div>
    <div class="content" v-if="!isAnswer">
      <!-- 渲染问题列表 -->
      <div
        class="content-item"
        v-for="(item, index) in answerVal"
        :key="index"
        :class="{ active: currentitem === index }"
      >
        <div class="content-item-title" @click="clickme(item, index)">
          <span>·</span>
          <div class="list">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <!-- 答案层 遮罩 -->
    <div class="answer" v-else>
      <div class="back" @click="isAnswer = false">＜返回</div>
      <br />
      <!-- <div class="close" @click="isAnswer=false">x</div> -->
      <div class="answer-title">{{ answerValitem.title }}</div>
      <!-- <div class="answer-info">
        <div class="answer-info-date">
          发布时间：2021-01-27 17:33:33
        </div>
        <div class="answer-info-count">
          查看数：268
        </div>
      </div> -->
      <div class="answer-hengxian"></div>
      <div class="ql-editor answer-content" v-html="answerValitem.answer"></div>
    </div>
  </div>
</template>

<script>
import { menus, menus2, problems } from "@/assets/questionList.js";
import { getHelp } from "@/server/api";

function hasClass(el, clsName) {
  if (el.classList.contains(clsName)) return true;
}
function trim(x) {
  return x.replace(/^\s+|\s+$/gm, "");
}
function addClass(el, cls) {
  if (!el) return;
  var curClass = el.className;
  var classes = (cls || "").split(" ");

  for (var i = 0, j = classes.length; i < j; i++) {
    var clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.add(clsName);
    } else if (!hasClass(el, clsName)) {
      curClass += " " + clsName;
    }
  }
  if (!el.classList) {
    el.className = curClass;
  }
}
function removeClass(el, cls) {
  if (!el || !cls) return;
  var classes = cls.split(" ");
  var curClass = " " + el.className + " ";

  for (var i = 0, j = classes.length; i < j; i++) {
    var clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.remove(clsName);
    } else if (hasClass(el, clsName)) {
      curClass = curClass.replace(" " + clsName + " ", " ");
    }
  }
  if (!el.classList) {
    el.className = trim(curClass);
  }
}

export default {
  data() {
    return {
      showContentLoading:false,
      val: {}, //val:{id:0,title:'xxx',ctx:['problem1','problem2','problem3'...]}
      currentitem: 0, //右侧list当前项
      isAnswer: false, //回答框
      answerVal: {}, //二级标题下的问题title
      answerValitem: {}, //问题的answer
      show: false, //二级菜单下的问题块
    };
  },
  created() {},
  methods: {
    //接受父传递值
    getData(params) {
      if (Array.isArray(params) || params === "hot") {
        this.val = {
          name: "热门问题",
        };
        if (localStorage.getItem("hotProblem")) {
          this.answerVal = JSON.parse(localStorage.getItem("hotProblem"));
        } else {
          this.getHot();
        }
      } else {
        this.val = params;

        this.getQita(params);
      }
    },

    //获取hot问题
    getHot() {
      this.showContentLoading = true;
      let data = {
        isHotspot: 1,
      };

      getHelp(data).then((res) => {
        if (res.code == 200 && res.data) {
          this.answerVal = res.data.filter((p) => {
            return p.piece === 3;
          });
          if (!localStorage.getItem("hotProblem")) {
            localStorage.setItem("hotProblem", JSON.stringify(this.answerVal));
          }
        }
        this.showContentLoading = false;
      });
    },

    //筛选 学生的问题
    getQita(p) {
      let data = {
        piece: 3, //学会的问题类型
        port: Number(p.nodeId.slice(-1)), //1：系统操作  3：其他
      };
      getHelp(data).then((res) => {
        if (res.code == 200 && res.data) {
          this.answerVal = res.data;
        }
      });
    },

    //关闭回答框
    closeanswer() {
      if (this.isAnswer) {
        this.isAnswer = false;
      }
      if (this.show) {
        this.show = false;
      }
    },
    //问题选择
    clickme(val, idx) {
      this.currentitem = idx;
      this.isAnswer = true;
      this.answerValitem = val;
    },
    //联想问题选择
    clickme2(val) {
      this.isAnswer = true;
      this.answerValitem = val;
      // console.log(val)
      // this.$parent.lianxiangVal = val.title
      // this.$parent.$refs.help.showlianxiang = false
    },

    beforeEnter(el) {
      addClass(el, "collapse-transition");
      el.style.height = "0";
    },
    enter(el) {
      if (el.scrollHeight !== 0) {
        el.style.height = el.scrollHeight + "px";
      } else {
        el.style.height = "";
      }

      el.style.overflow = "hidden";
    },

    afterEnter(el) {
      removeClass(el, "collapse-transition");
      el.style.height = "";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
      el.style.overflow = "hidden";
    },
    leave(el) {
      if (el.scrollHeight !== 0) {
        addClass(el, "collapse-transition");
        el.style.height = 0;
      }
    },
    afterLeave(el) {
      removeClass(el, "collapse-transition");
      el.style.height = "";
    },
  },
};
</script>

<style scoped>
@import "../../style/all.css";
</style>

<style lang="stylus" scoped>
@import './helpComp.styl';
</style>
